label {
  display: block;
  margin-top: 10px;
}

.card-container.card {
  max-width: 350px !important;
  padding: 40px 40px;
}

.card {
  background-color: #f7f7f7;
  padding: 20px 25px 30px;
  margin: 0 auto 25px;
  margin-top: 50px;
  -moz-border-radius: 2px;
  -webkit-border-radius: 2px;
  border-radius: 2px;
  -moz-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  -webkit-box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
  box-shadow: 0px 2px 2px rgba(0, 0, 0, 0.3);
}

.profile-img-card {
  width: 96px;
  height: 96px;
  margin: 0 auto 10px;
  display: block;
  -moz-border-radius: 50%;
  -webkit-border-radius: 50%;
  border-radius: 50%;
}

body,
.App {
  width: 100%;
  height: 100vh;
  overflow: scroll;
  font-size: 16px;
}

button {
  background: none;
  border: 0;
  margin: 0;
  padding: 0;
  font-size: inherit;
  font-family: inherit;
  cursor: pointer;
  color: #08c;
}
button:hover {
  color: #04c;
}

.App {
  display: flex;
}


.app-sidebar {
  width: 30%;
  min-width: 30%;
  height: 100vh;
  border-right: 1px solid #ddd;
}

.app-sidebar-header {
  display: flex;
  justify-content: space-between;
  padding: 25px;
}
.app-sidebar-header h1 {
  margin: 0;
}

.app-sidebar-notes {
  height: calc(100vh - 78px);
  overflow-y: scroll;
}
.app-sidebar-note {
  padding: 25px;
  cursor: pointer;
}
.sidebar-note-title {
  display: flex;
  justify-content: right;
}
.app-sidebar-note button {
  color: crimson;
}
.app-sidebar-note p {
  margin: 10px 0;
}
.app-sidebar-note small {
  display: block;
  color: #999;
}

.app-sidebar-note:hover {
  background: #ddd;
}
.app-sidebar-note.active,
.app-sidebar-note.active small {
  background: #343a40;
  color: white;
}


.app-main {
  display: flex;
  flex-wrap:wrap;
  height: 100vh;
}
.app-main-note-edit,
.app-main-note-preview {
  width: 50%;
  min-width: 500px;
  max-height: 80vh;
}


.app-preview-wrapper{
  display: flex;
  flex-direction: column;
  margin-top: 35px;
}
.app-main-note-edit {
  padding: 25px;
}

.app-main-note-edit input,
textarea {
  display: block;
  border: 1px solid #ddd;
  margin-bottom: 20px;
  width: 100%;
  height: 80vh;
  padding: 10px;
  resize: none;
  font-size: inherit;
  font-family: inherit;
}
@media only screen and (max-width: 1000px){
  .app-main-note-edit,
  .app-main-note-preview {
  width: 100%;
  height: 40vh;
  }
  textarea {
    height: 40vh;
  }
}
.app-main-note-edit input {
  height: 50px;
  font-size: 2rem;
}

/* Preview */
.app-main-note-preview {
  margin-top: 50px;
  border-top: 1px solid #ddd;
  overflow-y: scroll;
  background: rgba(0, 0, 0, 0.02);
}


.markdown-preview {
  padding: 0 25px 25px 25px;
  font-size: 1rem;
  line-height: 2rem;
  white-space: pre-wrap;
}
